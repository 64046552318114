import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import 'paper-css/paper.min.css'
import './assets/css/fonts.css'

Vue.config.productionTip = false

Vue.use(VueI18n)
// Vue.use(MdTable);

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD',  currencyDisplay: 'symbol'
    }
  },
  'de-DE': {
    currency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
    }
  }
}

const dateTimeFormats = {
  'en-US': {
    short: {
      day: 'numeric', month: 'numeric', year: 'numeric'
    },
    long: {
      day: 'numeric', month: 'long', year: 'numeric'
    }
  },
  'de-DE': {
    short: {
      day: 'numeric', month: 'numeric', year: 'numeric'
    },
    long: {
      day: 'numeric', month: 'long', year: 'numeric'
    }
  }
};

const i18n = new VueI18n({
  locale: 'de-DE',
  numberFormats: numberFormats,
  dateTimeFormats: dateTimeFormats,
  silentFallbackWarn: true,
});

new Vue({
  render: h => h(App),
  i18n,
}).$mount('#app')
