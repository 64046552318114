<template>
    <div id="app">
        <Invoice v-if="renderType=='invoice'"/>
        <Quote v-else-if="renderType=='quote'"/>
    </div>
</template>

<script>
    import Invoice from './components/Invoice.vue'
    import Quote from './components/Quote.vue'


    export default {
        name: 'app',
        components: {
            Invoice,
            Quote
        },
        data() {
            return {
                renderType: "",
            };
        },
        created() {
            let url = new URL(window.location.href);
            this.renderType = url.searchParams.get('renderType');
        },
    }
</script>

<style>

    @page {
        size: A4;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
