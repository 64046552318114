<template>
    <div class="invoice sheet">
        <div
                v-if="loaded"
                id="loaded"
        />
        <div class="page">
            <div v-if="valid">
                <section class="letterhead">
                    <header>
                        <h1 class="logo_container">
                            <img src="../assets/logo.png">
                        </h1>

                        <h2 class="title">{{invoice.Locale.Title}} {{ invoice.InvoiceId }}</h2>
                    </header>

                    <section class="addresses">
                        <address>
                            <label>{{invoice.Locale.From}}</label>
                            <div class="address">
                                <span v-if="hasValue(invoice.BillFrom.CompanyName)">{{invoice.BillFrom.CompanyName}}<br></span>
                                <span v-if="hasValue(invoice.BillFrom.ContactName)">{{invoice.BillFrom.ContactName}}<br></span>
                                {{invoice.BillFrom.Street}}<br>
                                {{invoice.BillFrom.ZIP}} {{invoice.BillFrom.City}}<br>
                                {{invoice.BillFrom.Country}}
                            </div>
                        </address>

                        <address>
                            <label>{{invoice.Locale.To}}</label>
                            <div class="address">
                                <span v-if="hasValue(invoice.BillTo.CompanyName)">{{invoice.BillTo.CompanyName}}<br></span>
                                <span v-if="hasValue(invoice.BillTo.ContactName)">{{invoice.BillTo.ContactName}}<br></span>
                                {{invoice.BillTo.Street}}<br>
                                {{invoice.BillTo.ZIP}} {{invoice.BillTo.City}}<br>
                                {{invoice.BillTo.Country}}
                            </div>
                        </address>
                    </section>

                    <section class="details">
                        <div class="details_row">
                            <label>{{invoice.Locale.InvoiceNo}}</label>
                            <p>{{ invoice.InvoiceId }}</p>
                        </div>
                        <div class="details_row">
                            <label>{{invoice.Locale.ServiceDate}}</label>
                            <p>{{ $d( new Date(invoice.ServiceDate), 'short')}}</p>
                        </div>
                        <div class="details_row">
                            <label>{{invoice.Locale.InvoiceDate}}</label>
                            <p>{{ $d( new Date(invoice.InvoiceDate), 'short')}}</p>
                        </div>

                        <div v-if="!invoice.MonthlyRates" class="details_row">
                            <label>{{invoice.Locale.DueDate}}</label>
                            <p>{{ $d( new Date(invoice.InvoiceDueDate), 'short')}}</p>
                        </div>

                        <div  v-else="invoice.MonthlyRates" class="details_row">
                          <label>{{invoice.Locale.DueDate}}</label>
                          <p>{{invoice.Locale.MonthlyRateDue}}</p>
                        </div>
                    </section>
                </section>
                <section class="items">
                    <div class="items-row items-row-head">
                        <div class="items-column items-column-description">
                            {{invoice.Locale.Description}}
                        </div>
                        <div class="items-column items-column-number items-column-quantity">
                            {{invoice.Locale.Amount}}
                        </div>
                        <div class="items-column items-column-number items-column-rate">
                            {{invoice.Locale.Price}}
                        </div>
                        <div class="items-column items-column-number items-column-amount">
                            {{invoice.Locale.ItemTotal}}
                        </div>
                    </div>
                    <div
                            v-for="item in invoice.items"
                            :key="item.Number"
                            class="item items-row"
                    >
                        <div class="items-column items-column-description">
                            <p class="items-body">{{ item.Description }}</p>
                        </div>
                        <div class="items-column items-column-number items-column-quantity">
                            <p class="items-body">{{ item.ItemType == "Time" ?
                                $options.filters.floatToTime(item.Quantity) : Math.ceil(item.Quantity) }}</p>
                        </div>
                        <div class="items-column items-column-number items-column-rate">
                            <p class="items-body">{{ $n(item.Rate , 'currency') }}</p>
                        </div>
                        <div class="items-column items-column-number items-column-amount">
                            <p class="items-body">{{ $n(item.Amount , 'currency') }}</p>
                        </div>
                    </div>
                    <div class="items-row items-row-foot">
                        <div class="items-column items-column-description">

                        </div>
                        <div class="items-column bottom-border">
                            <p>{{invoice.Locale.Total}}</p>
                        </div>

                        <div v-if="invoice.MonthlyRates" class="items-column items-column-number bottom-border">
                          <p>{{ $n(invoice.TotalMonthly, 'currency') }}</p>
                        </div>

                        <div class="items-column items-column-number bottom-border">
                            <p>{{ $n(invoice.Total , 'currency') }}</p>
                        </div>
                    </div>
                </section>
                <section class="note">
                    <vue-markdown>{{ invoice.InvoiceNote }}</vue-markdown>
                </section>
                <footer>
                    <p>
                        <strong>Email</strong>: daniel.eder@tridentconsulting.at<br>
                        <strong>Phone</strong>: +436802108978<br>
                        <strong>Web</strong>: <a href="https://tridentconsulting.at/">https://tridentconsulting.at/</a>
                    </p>
                </footer>
            </div>
            <div v-else><h1>Please specify a valid invoice id and api key</h1></div>
        </div>
    </div>
</template>

<script>
    import VueMarkdown from 'vue-markdown'
    import Airtable from 'airtable';

    let invoiceId = 0;
    let base = {};

    export default {
        name: "Invoice",
        components: {
            VueMarkdown,
        },
        props: {},
        data() {
            return {
                client: {},
                invoice: {},
                valid: false,
                loaded: false,
            };
        },
        created() {
            this.fetchInvoiceData();
        },
        filters: {
            floatToTime: function (value) {
                var hours = parseInt(Number(value));
                var minutes = Math.round((Number(value) - hours) * 60);
                return hours + ':' + ("0" + minutes).slice(-2) + "h";
            },
        },
        computed: {
        },
        methods: {
            hasValue(value){
                return typeof value !== 'undefined' && value !== null && value !== '';
            },
            async fetchInvoiceData() {
                let url = new URL(window.location.href);
                invoiceId = url.searchParams.get('invoiceId');
                let apiKey = url.searchParams.get('apiKey');
                this.valid = (invoiceId !== null && invoiceId !== '') && (apiKey !== null && apiKey !== '');
                if (!this.valid)
                    return;
                base = new Airtable({apiKey: apiKey}).base('appcOv9Ojlpsv33Ai');

                let invoices = await base("Invoices").select({
                    filterByFormula: `{Invoice ID} = '${invoiceId}'`,
                }).all();

                let invoiceData = invoices[0].fields;
                let invoice = {};
                invoice.BillTo = {};
                invoice.BillFrom = {};
                invoice.Locale = {};

                invoice.InvoiceId = invoiceData["Invoice ID"];
                invoice.ServiceDate = invoiceData["Service Date"];
                invoice.InvoiceDate = invoiceData["Invoice Date"];
                invoice.InvoiceDueDate = invoiceData["Invoice Due Date"];
                invoice.MonthlyRates = invoiceData["Monthly Rates"];
                invoice.Total = invoiceData["Total"];
                invoice.TotalMonthly = invoice.Total / 12.0;

                let invoiceNoteData = (await base("Invoice Notes").find(invoiceData["Invoice Note"][0])).fields;
                invoice.InvoiceNote = invoiceNoteData["Invoice Note"];

                let clientData = (await base("Clients").find(invoiceData["Client"][0])).fields;
                invoice.BillTo.CompanyName = clientData["Billing Company Name"];
                invoice.BillTo.ContactName = clientData["Billing Contact Name"];
                invoice.BillTo.Street = clientData["Billing Street"];
                invoice.BillTo.ZIP = clientData["Billing ZIP"];
                invoice.BillTo.City = clientData["Billing City"];
                invoice.BillTo.Country = clientData["Billing Country"];

                let vendorData = (await base("Vendors").find(clientData["Vendor"][0])).fields;
                invoice.BillFrom.CompanyName = vendorData["Billing Company Name"];
                invoice.BillFrom.ContactName = vendorData["Billing Contact Name"];
                invoice.BillFrom.Street = vendorData["Billing Street"];
                invoice.BillFrom.ZIP = vendorData["Billing ZIP"];
                invoice.BillFrom.City = vendorData["Billing City"];
                invoice.BillFrom.Country = vendorData["Billing Country"];

                invoice.items = (await Promise.all(invoiceData["Items"].map(id => base("Invoice Items").find(id))))
                    .map(function (invoiceItemData) {
                        invoiceItemData = invoiceItemData.fields;
                        let invoiceItem = {};
                        invoiceItem.Number = invoiceItemData["Number"];
                        invoiceItem.Description = invoiceItemData["Description"];
                        invoiceItem.ItemType = invoiceItemData["Item Type"];
                        invoiceItem.Quantity = invoiceItemData["Quantity"];
                        invoiceItem.Rate = invoiceItemData["Rate"];
                        invoiceItem.Amount = invoiceItemData["Amount"];
                        return invoiceItem;
                    });

                let locale = (await base("Invoice Localizations").find(invoiceData["Locale Code"][0])).fields;
                invoice.Locale.Title = locale["Title"];
                invoice.Locale.From = locale["From"];
                invoice.Locale.To = locale["To"];
                invoice.Locale.InvoiceNo = locale["Invoice No."];
                invoice.Locale.ServiceDate = locale["Service Date"];
                invoice.Locale.InvoiceDate = locale["Invoice Date"];
                invoice.Locale.DueDate = locale["Due Date"];
                invoice.Locale.MonthlyRateDue = locale["Monthly Rate Due"];
                invoice.Locale.Description = locale["Description"];
                invoice.Locale.Amount = locale["Amount"];
                invoice.Locale.Price = locale["Price"];
                invoice.Locale.ItemTotal = locale["Item Total"];
                invoice.Locale.Total = locale["Total"];

                this.invoice = invoice;
            },
        },
        i18n: { // `i18n` option, setup locale info for component
            messages: {
                de: {message: {hello: 'hello component1'}},
                en: {message: {hello: 'こんにちは、component1'}}
            }
        }
    };
</script>

<style scoped>

    .invoice {
        color: rgb(51, 51, 51);
        font-family: "pn-r", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
        font-size: 14px;
        line-height: 1.4;
        font-weight: 400;
    }

    .page {
        padding: 25px 50px 50px 50px;
        float: left;
        box-sizing: border-box;
        width: 100%;
    }

    label {
        font-family: "pn-sb", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
        font-weight: 400;
        font-size: 14px;
        display: block;
    }

    .letterhead {
        margin-bottom: 10px;
        width: 100%;
        height: auto;
        display: inline-block;
    }

    .letterhead header {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 10px;
    }

    .letterhead header .logo_container {
        height: 125px;
        float: right;
    }

    .letterhead header .logo_container img {
        max-width: 100%;
        max-height: 100%;
        float: right;
    }

    .letterhead .addresses {
        width: 40%;
        float: left;
        margin: 15px 20px 0 0;

    }

    .letterhead .addresses address {
        margin: 0 0 15px;
        padding: 0;
        font-style: normal;
    }


    .letterhead .addresses address label {
        margin-bottom: 5px;
    }


    .letterhead .details {
        margin: 25px 0 0;
        width: 35%;
        float: right;
    }

    .letterhead .details .details_row label {
        margin-bottom: 5px;
    }

    .letterhead .details .details_row p {
        margin-top: 0px;
    }


    .items-row {
        display: flex;
        page-break-inside: avoid;

    &
    :not(:first-child) {
        margin-top: setting-spacing(l);
    }

    }

    .items-row-head {
        padding-bottom: setting-spacing(s);
        border-bottom: 1px solid rgb(231, 231, 231);
        font-family: "pn-sb", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
    }

    .items-row-foot {
        padding-top: setting-spacing(s);
        border-top: 1px solid rgb(231, 231, 231);
        font-family: "pn-sb", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
    }

    .items-row-foot .bottom-border {
        border-bottom: 1px solid rgb(231, 231, 231);
    }

    .items-column {
        flex-grow: 1;
    }

    .items-column-description {
        width: 60%;
    }

    .items-column-rate {
        width: 15%;
    }

    .items-column-quantity {
        width: 10%;
    }

    .items-column-amount {
        width: 15%;
    }

    .items-column-number {
        text-align: right;
    }

    .items-body {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .note {
        /*margin-top: -20px;*/
    }

    footer {
        border-top: 1px solid rgb(231, 231, 231);
    }
</style>
